<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'

export default {
	name: 'AbstractViewer',
	mixins: [DocumentVersionsModuleGuard],
	props: {
		src: {
			type: String,
			required: true
		}
	},
	created: function () {
		this.setIsStaticViewer()
	},
	methods: {
		setCurrentPage: function (pageNumber = 1) {
			this.$emit('update:page', pageNumber)
		},
		setIsStaticViewer: function () {
			if (this.hasOwnProperty('isStatic')) {
				this.$emit('update:is-static', this.isStatic)
			} else {
				this.$emit('update:is-static', true)
			}
		},
		setTotalPages: function (totalPages = 1) {
			this.$emit('update:total-pages', totalPages)
		}
	},
	render: function () {
		return null
	}
}
</script>
