<template>
	<v-layout fill-height justify-center align-center row>
		<viewer :options="viewerOptions" :trigger="src" @inited="inited">
			<img :src="src" style="display: none" />
		</viewer>
	</v-layout>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import AbstractViewer from '@/components/Documents/Preview/Viewers/AbstractViewer'
import ZoomCatcher from '@/mixins/ZoomCatcher'

const ZOOM_STEP = 0.25
const MOVE_STEP = 10
const FIT_MODE_SAVED_KEY = 'ImageViewer-FitMode'

export default {
	name: 'ImageViewer',
	extends: AbstractViewer,
	mixins: [DocumentVersionsModuleGuard, ZoomCatcher],
	data: function () {
		return {
			viewerOptions: {
				// Enable a modal backdrop, specify `static` for a backdrop which doesn't close the modal on click.
				backdrop: 0,
				// Show the button on the top-right of the viewer.
				button: false,
				// Show the navbar.
				navbar: false,
				// Specify the visibility and the content of the title.
				title: false,
				// Show the toolbar.
				toolbar: false,
				// Custom class name(s) to add to the viewer's root element.
				className: '',
				// Define where to put the viewer in modal mode.
				container: 'body',
				// Filter the images for viewing. Return true if the image is viewable.
				filter: null,
				// Enable to request fullscreen when play.
				fullscreen: false,
				// Define the initial index of image for viewing.
				initialViewIndex: 0,
				// Enable inline mode.
				inline: true,
				viewed: () => {
					this.restoreSavedFit()
				},
				// The amount of time to delay between automatically cycling an image when playing.
				interval: 5000,
				// Enable keyboard support.
				keyboard: false,
				// Indicate if show a loading spinner when load image or not.
				loading: true,
				// Indicate if enable loop viewing or not.
				loop: false,
				// Min width of the viewer in inline mode.
				minWidth: 200,
				// Min height of the viewer in inline mode.
				minHeight: 100,
				// Enable to move the image.
				movable: true,
				// Enable to rotate the image.
				rotatable: true,
				// Enable to scale the image.
				scalable: false,
				// Enable to zoom the image.
				zoomable: true,
				// Enable to zoom the current image by dragging on the touch screen.
				zoomOnTouch: true,
				// Enable to zoom the image by wheeling mouse.
				zoomOnWheel: true,
				// Enable to slide to the next or previous image by swiping on the touch screen.
				slideOnTouch: true,
				// Indicate if toggle the image size between its natural size and initial size when double click on the image or not.
				toggleOnDblclick: true,
				// Show the tooltip with image ratio (percentage) when zoom in or zoom out.
				tooltip: true,
				// Enable CSS3 Transition for some special elements.
				transition: true,
				// Define the CSS `z-index` value of viewer in modal mode.
				zIndex: 2015,
				// Define the CSS `z-index` value of viewer in inline mode.
				zIndexInline: 0,
				// Define the ratio when zoom the image by wheeling mouse.
				zoomRatio: 0.1,
				// Define the min ratio of the image when zoom out.
				minZoomRatio: 0.01,
				// Define the max ratio of the image when zoom in.
				maxZoomRatio: 100
			}
		}
	},
	watch: {
		src: {
			handler: function () {
				this.setCurrentPage()
				this.setTotalPages()
			},
			immediate: true
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.preview.ZOOM_IN, action: this.zoomIn },
				{ event: this.events.preview.ZOOM_OUT, action: this.zoomOut },
				{ event: this.events.preview.ZOOM_FIT, action: this.zoomFit }
			]
		},
		getKeyboardEventsActionsMapping: function () {
			// return [
			// 	{ key: this.keyCodes.arrow.up, action: this.moveUp },
			// 	{ key: this.keyCodes.arrow.down, action: this.moveDown }
			// ]
			return [
				{
					actions: [
						{
							key: this.keyboard.arrow.up,
							action: this.moveUp
						},
						{
							key: this.keyboard.arrow.down,
							action: this.moveDown
						}
					]
				}
			]
		},
		/**
		 * @param {object} viewer
		 */
		inited: function (viewer) {
			this.$viewer = viewer
			this.$viewer.show()
		},
		moveUp: function () {
			this.$viewer.move(0, -1 * MOVE_STEP)
		},
		moveDown: function () {
			this.$viewer.move(0, MOVE_STEP)
		},
		onResetZoom: function () {
			this.$viewer.reset()
		},
		onZoomIn: function () {
			this.zoomIn()
		},
		onZoomOut: function () {
			this.zoomOut()
		},
		restoreSavedFit: function () {
			if (typeof localStorage.getItem(FIT_MODE_SAVED_KEY) !== 'undefined') {
				this.zoomFit(localStorage.getItem(FIT_MODE_SAVED_KEY), false)
			}
		},
		zoomFit: function (value, updateLocalStorage = true) {
			if (this.$viewer && this.$viewer.initialImageData && this.$viewer.parentData) {
				this.$viewer.reset()
				this.$viewer.move(0, 32)

				switch (value) {
					case 'width':
						if (this.$viewer.initialImageData.naturalWidth && this.$viewer.parentData.width) {
							this.$viewer.zoomTo(this.$viewer.parentData.width / this.$viewer.initialImageData.naturalWidth)
						}
						break
					case 'height':
						if (this.$viewer.initialImageData.naturalHeight && this.$viewer.parentData.height) {
							this.$viewer.zoomTo((this.$viewer.parentData.height - 64) / this.$viewer.initialImageData.naturalHeight)
						}
						break
				}

				if (updateLocalStorage) {
					localStorage.setItem(FIT_MODE_SAVED_KEY, value)
				}
			}
		},
		zoomIn: function () {
			if (this.$viewer) {
				this.$viewer.zoom(ZOOM_STEP)
			}
		},
		zoomOut: function () {
			if (this.$viewer) {
				this.$viewer.zoom(-1 * ZOOM_STEP)
			}
		}
	}
}
</script>
